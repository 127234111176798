import React from "react";
import { NavLink } from "react-router-dom";
import strings from "../Language";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: localStorage.getItem("language"),
            menu_show: false,
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
    }

    /**
     * Change Language
     * @param {string} obj 
     */
    handleChange = async (event) => {
        let obj = event.target.value;
        if (obj === "th") {
            strings.setLanguage('th');
            await localStorage.setItem("language", 'th');
            this.setState({ lang: 'th' });
            window.location.reload();
        }
        else {
            strings.setLanguage('en');
            await localStorage.setItem("language", 'en');
            this.setState({ lang: 'en' });
            window.location.reload();
        }
    };

    render() {
        return (
            <>
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                        <div className="d-flex">
                            <div className={"dropdown " + (this.state.menu_show ? 'show' : '')}
                                type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                onClick={() => this.setState({ menu_show: !this.state.menu_show })}>
                                <img src="assets/images/flag.png" alt="" />
                                <a href="javascript:void(0);" className="dropdown-toggle">UK</a>
                                <ul className={"dropdown-menu " + (this.state.menu_show ? 'show' : '')}>
                                    <li><a className="dropdown-item" href="javascript:void(0);"><img src="assets/images/cn.png" alt="" /> CN</a></li>
                                    <li><a className="dropdown-item" href="javascript:void(0);"><img src="assets/images/kr.png" alt="" /> KR</a></li>
                                    <li><a className="dropdown-item" href="javascript:void(0);"><img src="assets/images/vn.png" alt="" /> VN</a></li>
                                    <li><a className="dropdown-item" href="javascript:void(0);"><img src="assets/images/in.png" alt="" /> IN</a></li>
                                    <li><a className="dropdown-item" href="javascript:void(0);"><img src="assets/images/es.png" alt="" /> ES</a></li>
                                    <li><a className="dropdown-item" href="javascript:void(0);"><img src="assets/images/cz.png" alt="" /> CZ</a></li>
                                    <li><a className="dropdown-item" href="javascript:void(0);"><img src="assets/images/pt.png" alt="" /> PT</a></li>
                                    <li><a className="dropdown-item" href="javascript:void(0);"><img src="assets/images/ru.png" alt="" /> RU</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </>
        );
    }
}

export default Header;