import React from 'react'
import { useLocation } from 'react-router-dom'
import strings from "../Language";
import { Helmet } from 'react-helmet';
import HeaderAuth from '../Layout/HeaderAuth';

const NotFound = () => {
  const location = useLocation()

  return (
    <>
      <Helmet><title>{"FUTURA | Not Found"}</title></Helmet>
      <HeaderAuth />
      <div className="form-cover error-middle">
        <h1>404</h1>
        <p>
          {"We could not find"} <u>{location.pathname}</u> {"page."}
        </p>
      </div>
    </>
  )
}

export default NotFound
