import React from 'react';
import { Link } from 'react-router-dom'
import strings from '../../components/Language';
import { getToken, getUserData, number_format, storeUserData } from '../../components/Util';
import { Helmet } from 'react-helmet';

import { api } from '../../components/config/api';
import Modal from 'react-modal';
import Iframe from 'react-iframe';
import loading from './../../assets/images/loading.gif';
import cancel from './../../assets/images/cancel.png';

import Header from '../../components/Layout/Header';
import SideBarWeb from '../../components/Layout/SideBarWeb';
import SideBarMobile from '../../components/Layout/SideBarMobile';
import LatestNews from '../../components/Layout/LatestNews';
import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment/moment';

class DashboardScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: localStorage.getItem("language"),
			is_loading: false,
			is_load: false,
			access_token: "",
			is_modal: false,
			random_key: 1,

			user_data: {},
			credit_data: {},
			activeTab: "FUDI",

			creditListData: [],
			prev_page_url: "",
			next_page_url: "",

			avatar: "",

		}
	}

	async componentDidMount() {
		let self = this;
		self.setState({ is_loading: true });
		window.scrollTo(0, 0)
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			var user_data = getUserData();
			console.log(token);
			self.setState({
				access_token: token,
				user_data: (user_data && user_data.user) ? user_data.user : {},
				credit_data: (user_data && user_data.credit) ? user_data.credit : '',
				is_loading: false
			});

			setTimeout(() => {
				self.getDataDashboard(api.dashboard)
				self.getDataCredit(api.credit_fudi, "FUDI")
			});
		}
	}

	getDataDashboard(API) {
		if (API) { } else { return false; }
		var self = this;
		axios({
			method: 'GET',
			url: API,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				console.log(ress.data.data);
				self.setState({
					user_data: (ress.data && ress.data.data && ress.data.data.user) ? ress.data.data.user : {},
					credit_data: (ress.data && ress.data.data && ress.data.data.credit) ? ress.data.data.credit : '',
				});
				console.log(self.state.user_data);
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
		}).catch(function (e) {
			//toast.error(e.message);
		});
	};

	getDataCredit(API, activeTab) {
		if (API) { } else { return false; }
		var self = this;
		axios({
			method: 'GET',
			url: API,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				console.log(ress.data.data);
				self.setState({
					creditListData: (ress.data.data && ress.data.data.rows && ress.data.data.rows.data) ? ress.data.data.rows.data : [],
					prev_page_url: (ress.data.data && ress.data.data.rows && ress.data.data.rows.prev_page_url) ? ress.data.data.rows.prev_page_url : '',
					next_page_url: (ress.data.data && ress.data.data.rows && ress.data.data.rows.next_page_url) ? ress.data.data.rows.next_page_url : '',
				});
				self.setState({ activeTab: activeTab });
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
		}).catch(function (e) {
			//toast.error(e.message);
		});
	};

	avatarUpload(avatar_url) {
		const self = this;
		axios({
			method: 'POST',
			url: api.profile_update_avatar,
			data: {
				avatar: avatar_url
			},
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				"Content-Type": "application/json"
			}
		}).then(function (ress) {
			// self.setState({ is_modal: false });
			if (ress.data && ress.data.error === 200) {
				toast.success(ress.data.message);
				window.location.reload();
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
		}).catch(function (e) {
			//toast.error(e.message);
		});
	}

	copyRefcode() {
		navigator.clipboard.writeText((this.state.user_data && this.state.user_data.username) ? this.state.user_data.username : '-')
		toast.success("Meta ID/Refcode Copied!");
	}

	copyWalletAddress() {
		navigator.clipboard.writeText((this.state.user_data && this.state.user_data.wallet_address) ? this.state.user_data.wallet_address : '-')
		toast.success("Wallet Address Copied!");
	}

	isModalOpen() {
		let self = this;
		self.setState({ is_modal: true });

		setTimeout(() => {
			self.loadModalData()
		}, 1000);

	}

	loadModalData() {
		let self = this;

		const subdomain = 'demo';
		const frame = document.getElementById('frame');

		// frame.src = `https://${subdomain}.readyplayer.me/avatar?frameApi`;
		frame.src = `https://${subdomain}.readyplayer.me/avatar?frameApi&clearCache`;

		window.addEventListener('message', subscribe);
		document.addEventListener('message', subscribe);

		function subscribe(event) {
			const json = parse(event);
			if (json?.source !== 'readyplayerme') {
				return;
			}

			if (json.eventName === 'v1.frame.ready') {
				frame.contentWindow.postMessage(
					JSON.stringify({
						target: 'readyplayerme',
						type: 'subscribe',
						eventName: 'v1.**'
					}),
					'*'
				);
			}

			if (json.eventName === 'v1.avatar.exported') {

				self.setState({ is_load: true });
				const params =
				{
					// Replace with the .glb URL for the desired avatar.
					model: json.data.url,
					// Type of portrait to render.
					scene: "fullbody-portrait-v1",
					// Optional: Pose. Default: T-pose.
					armature: "ArmatureTargetMale",
					// Optional: Facial expression. Default: Empty.
					"blendShapes": { "Wolf3D_Avatar": { "mouthSmile": 0.2 } }
				}
				console.log(params);
				const http = new XMLHttpRequest()
				http.open("POST", "https://render.readyplayer.me/render")
				http.setRequestHeader("Content-type", "application/json")
				http.send(JSON.stringify(params))
				http.onload = function () {
					console.log("JSON.parse(http.responseText)[--------------------", JSON.parse(http.responseText)["renders"]);
					self.avatarUpload(JSON.parse(http.responseText)["renders"][0]);
					self.setState({ is_load: false, random_key: self.state.random_key + 1 });
				}

			}
		}

		function parse(event) {
			try {
				return JSON.parse(event.data);
			} catch (error) {
				return null;
			}
		}

	}

	render() {
		return (
			<>
				<Helmet><title>{"FUTURA | Dashboard"}</title></Helmet>
				<div className="dashboard">
					<Header />

					<div className="container-fluid">
						<div className="row">
							<SideBarWeb />

							{!this.state.is_loading ?
								<>
									<div className="col">
										<br />
										<h1>Hello!<br /> {(this.state.user_data && this.state.user_data.username) ? this.state.user_data.username : '-'}</h1>
										<div className="panel">
											<div className="row">
												<div className="col">
													<div className="pocket">
														<div className="px-2">
															<picture onClick={() => this.isModalOpen()} class="image_div">
																<img className='btnimg' src={(this.state.user_data && this.state.user_data.avatar_url) ? this.state.user_data.avatar_url : "assets/images/avtr.png"} alt="" />
															</picture>
														</div>
														<div className="box">
															<div className="row">
																<div className="col-auto mb-2">
																	Meta ID/Refcode:
																</div>
																<div className="col-auto mb-2">
																	MetaBOBO
																</div>
																<div className="col mb-2 text-end">
																	<a onClick={() => this.copyRefcode()} href="javascript:void(0);">Copy ID</a>
																</div>
															</div>
															<div className="row">
																<div className="col-auto mb-2">
																	Email Address:
																</div>
																<div className="col-auto mb-2">
																	{(this.state.user_data && this.state.user_data.email_masked) ? this.state.user_data.email_masked : '-'}
																</div>
																<div className="col mb-2 text-end">
																	<a href="javascript:void(0);">Edit</a>
																</div>
															</div>
															<div className="row">
																<div className="col-auto mb-2">
																	Wallet Address:
																</div>
																<div className="col-auto mb-2">
																	{(this.state.user_data && this.state.user_data.wallet_address_masked) ? this.state.user_data.wallet_address_masked : '-'}
																</div>
																<div className="col mb-2 text-end">
																	<a onClick={() => this.copyWalletAddress()} href="javascript:void(0);">Copy Address</a>
																</div>
															</div>
															<h3>Pocket Balance</h3>
															<div className="row">
																<div className="col-auto">
																	<div className="row">
																		<div className="col-auto mb-1">
																			FUD:
																		</div>
																		<div className="col-auto mb-1">
																			{(this.state.credit_data && this.state.credit_data.fud) ? number_format(this.state.credit_data.fud, 2, '.', ',') : '0'}
																		</div>
																	</div>
																	<div className="row green">
																		<div className="col-auto mb-1">
																			FUDI:
																		</div>
																		<div className="col-auto mb-1">
																			{(this.state.credit_data && this.state.credit_data.fudi) ? number_format(this.state.credit_data.fudi, 2, '.', ',') : '0'}
																		</div>
																	</div>
																	<div className="row blue">
																		<div className="col-auto">
																			FUDE:
																		</div>
																		<div className="col-auto">
																			{(this.state.credit_data && this.state.credit_data.fude) ? number_format(this.state.credit_data.fude, 2, '.', ',') : '0'}
																		</div>
																	</div>
																</div>
																<div className="col pt-2 text-end">
																	<br /><br />
																	<a href="javascript:void(0);" className="me-1">Deposit 🔒</a>
																	<a href="javascript:void(0);" className="me-1">Withdraw 🔒</a>
																	<a href="javascript:void(0);">Transfer 🔒</a>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col">
													<div className="placement">
														<h3>My Placement Release</h3>
														<div className="relese">
															<div className="row align-items-center">
																<div className="col-auto">
																	FUDI:
																</div>
																<div className="col-auto">
																	{(this.state.credit_data && this.state.credit_data.fudi) ? number_format(this.state.credit_data.fudi, 2, '.', ',') : '0'}
																</div>
																<div className="col text-end">
																	<a href="javascript:void(0);" className="btn btn-primary rounded-pill">Claim</a>
																</div>
															</div>
														</div>
														<div className="relese">
															<div className="row align-items-center">
																<div className="col-auto">
																	FUDE:
																</div>
																<div className="col-auto">
																	{(this.state.credit_data && this.state.credit_data.fude) ? number_format(this.state.credit_data.fude, 2, '.', ',') : '0'}
																</div>
																<div className="col text-end">
																	<a href="javascript:void(0);" className="btn btn-primary rounded-pill">Claim</a>
																</div>
															</div>
														</div>
														<div className="balance">
															<div className="row">
																<div className="col-auto">
																	Vault Balance:
																</div>
																<div className="col-auto">
																	{(this.state.credit_data && this.state.credit_data.vault) ? number_format(this.state.credit_data.vault, 2, '.', ',') : '0'}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<nav className="mt-4 mb-2">
												<div className="nav nav-tabs nav-justified" id="nav-tab" role="tablist">
													<button onClick={() => this.getDataCredit(api.credit_fudi, "FUDI")} className={"nav-link " + ((this.state.activeTab == "FUDI") ? 'active' : '')} id="nav-fudi-tab" data-bs-toggle="tab" data-bs-target="#nav-fudi" type="button" role="tab" aria-controls="nav-fudi" aria-selected="true">FUDI</button>
													<button onClick={() => this.getDataCredit(api.credit_fude, "FUDE")} className={"nav-link " + ((this.state.activeTab == "FUDE") ? 'active' : '')} id="nav-fude-tab" data-bs-toggle="tab" data-bs-target="#nav-fude" type="button" role="tab" aria-controls="nav-fude" aria-selected="false">FUDE</button>
													<button onClick={() => this.getDataCredit(api.credit_pocket, "POCKET")} className={"nav-link " + ((this.state.activeTab == "POCKET") ? 'active' : '')} id="nav-pocket-tab" data-bs-toggle="tab" data-bs-target="#nav-pocket" type="button" role="tab" aria-controls="nav-pocket" aria-selected="false">POCKET</button>
												</div>
											</nav>
											<div className="tab-content" id="nav-tabContent">
												<div className={"tab-pane fade " + ((this.state.activeTab == "FUDI") ? 'show active' : '')} id="nav-fudi" role="tabpanel" aria-labelledby="nav-fudi-tab" tabIndex="0">
													<div className="tbl-head">
														<div className="row align-items-center">
															<div className="col-4">
																Date/Time
															</div>
															<div className="col-4">
																Description
															</div>
															<div className="col-4">
																Amount
															</div>
														</div>
													</div>
													<div className="tab-detail">
														{this.state.creditListData && this.state.creditListData.length != 0 ?
															<>
																{this.state.creditListData.map((item, i) =>
																	<div className="tbl-row">
																		<div className="row align-items-center">
																			<div className="col-4">
																				{moment(item.created_at).format("YYYY-MM-DD HH:MM:SS")}
																			</div>
																			<div className="col-4">
																				{item.description}
																			</div>
																			<div className="col-4">
																				{(item.amount) ? number_format(item.amount, 5, '.', ',') : '0'}
																			</div>
																		</div>
																	</div>
																)}
															</>
															: null}
													</div>
												</div>
												<div className={"tab-pane fade " + ((this.state.activeTab == "FUDE") ? 'show active' : '')} id="nav-fude" role="tabpanel" aria-labelledby="nav-fude-tab" tabIndex="0">
													<div className="tbl-head">
														<div className="row align-items-center">
															<div className="col-4">
																Date/Time
															</div>
															<div className="col-4">
																Description
															</div>
															<div className="col-4">
																Amount
															</div>
														</div>
													</div>
													<div className="tab-detail">
														{this.state.creditListData && this.state.creditListData.length != 0 ?
															<>
																{this.state.creditListData.map((item, i) =>
																	<div className="tbl-row">
																		<div className="row align-items-center">
																			<div className="col-4">
																				{moment(item.created_at).format("YYYY-MM-DD HH:MM:SS")}
																			</div>
																			<div className="col-4">
																				{item.description}
																			</div>
																			<div className="col-4">
																				{(item.amount) ? number_format(item.amount, 5, '.', ',') : '0'}
																			</div>
																		</div>
																	</div>
																)}
															</>
															: null}
													</div>
												</div>
												<div className={"tab-pane fade " + ((this.state.activeTab == "POCKET") ? 'show active' : '')} id="nav-pocket" role="tabpanel" aria-labelledby="nav-pocket-tab" tabIndex="0">
													<div className="tbl-head">
														<div className="row align-items-center">
															<div className="col-3">
																Date/Time
															</div>
															<div className="col-5">
																Description
															</div>
															<div className="col-2">
																Amount
															</div>
															<div className="col-2">
																Balance
															</div>
														</div>
													</div>
													<div className="tab-detail">
														{this.state.creditListData && this.state.creditListData.length != 0 ?
															<>
																{this.state.creditListData.map((item, i) =>
																	<div className="tbl-row">
																		<div className="row align-items-center">
																			<div className="col-3">
																				{moment(item.created_at).format("YYYY-MM-DD HH:MM:SS")}
																			</div>
																			<div className="col-5">
																				{item.description}
																			</div>
																			<div className="col-2">
																				{(item.amount) ? number_format(item.amount, 5, '.', ',') : '0'}
																			</div>
																			<div className="col-1">
																				{(item.balance) ? number_format(item.balance, 5, '.', ',') : '0'}
																			</div>
																		</div>
																	</div>
																)}
															</>
															: null}
													</div>
												</div>
											</div>
											<div className="mt-2 text-end">
												<a onClick={() => this.getDataCredit(this.state.prev_page_url, this.state.activeTab)} href="javascript:void(0);" className="next">Prev Page </a>
												<a style={{ marginLeft: 10 }} onClick={() => this.getDataCredit(this.state.next_page_url, this.state.activeTab)} href="javascript:void(0);" className="next"> Next Page</a>
											</div>
										</div>
									</div>
									<LatestNews />
								</>
								: null}

						</div>
					</div>

					<Modal
						isOpen={this.state.is_modal}
						style={{ content: { padding: 0 } }}
						onRequestClose={() => window.location.reload()}
					>
						<div className='dashboard'>
							{/* <div className='modal_close' onClick={() => this.setState({ is_modal: !this.state.is_modal })}>
								<img src={cancel} alt='' />
							</div> */}

							{this.state.is_load ?
								<div className='text-center'>
									<img src={loading} alt='' />
								</div>
								:
								<Iframe
									width="100%"
									height="100%"
									id="frame" class="frame"
									key={this.state.random_key}
									clearCache
									allow="geolocation *; camera *; microphone *; clipboard-read; clipboard-write;"
									display="block"
									position="relative"
								/>
							}
						</div>
					</Modal>

				</div>
				<SideBarMobile />
			</>
		);
	}
}

export default DashboardScreen;
