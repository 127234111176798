import React from 'react';
import { Link } from 'react-router-dom'
import strings from '../../components/Language';
import { Helmet } from 'react-helmet';

import { api } from '../../components/config/api';
import HeaderAuth from '../../components/Layout/HeaderAuth';
import axios from 'axios';
import { toast } from 'react-toastify';

class RegisterScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: localStorage.getItem("language"),
			username: "",
			email: "",
			wallet_address: "",
			referral_username: "",
			otp: "",
			password: "",
			errorMessage: [],

		}
	}

	async componentDidMount() {
		
	}

	isSubmit() {
		var self = this;
		var json = {
			username: self.state.username,
			email: self.state.email,
			wallet_address: self.state.wallet_address,
			referral_username: self.state.referral_username,
			otp: self.state.otp,
		}
		self.setState({ errorMessage: [] });
		axios({
			method: 'POST',
			url: api.login,
			data: json,
			headers: {
				// 'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json;multipart/form-data;boundary=SOME_BOUNDARY',
				'Accept-Language': self.state.lang,
			}
		}).then(async function (ress) {
			if (ress.data.error == 202 || ress.data.error == '202') {
				if (ress.data.validation && ress.data.validation.length != 0) {
					self.setState({ errorMessage: ress.data.validation });
				} else {
					toast.error(ress.data.message);
				}
			} else {
				toast.success(ress.data.message);
				await localStorage.setItem('user_data', JSON.stringify((ress.data.data && ress.data.data.user) ? ress.data.data.user : []));
				await localStorage.setItem('user_token', (ress.data.data && ress.data.data.token) ? ress.data.data.token : '');
				toast.success(ress.data.message);
				self.props.history.go('/dashboard');
			}
		}).catch(function (e) {
			console.log(e);
		});
	};

	render() {
		return (
			<>
				<Helmet><title>{"FUTURA | Register"}</title></Helmet>
				<HeaderAuth />
				<div className="wrapper">
					<div className="container-fluid">
						<div className="main">
							<div className="row">
								<div className="col">
									<div className="sign">
										<form>
											<figure>
												<img src="assets/images/logo.png" alt="" />
											</figure>
											<p>Meta-Account</p>
											<div className="mb-3">
												<input className="form-control" type="text" placeholder="Create Meta ID" />
											</div>
											<div className="mb-3">
												<input className="form-control" type="text" placeholder="Enter Your Email Address" />
											</div>
											<div className="mb-3 position-relative">
												<input className="form-control" type="password" placeholder="Enter OTP" />
												<button type="button" className="btn btn-primary btn-request rounded-pill">Request OTP</button>
											</div>
											<div className="mb-3">
												<input className="form-control" type="password" placeholder="Enter Refcode" />
											</div>
											<button type="button" className="btn btn-primary rounded-pill">Create Account</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default RegisterScreen;
