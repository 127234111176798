import React from 'react';
import { Link } from 'react-router-dom'
import strings from '../../components/Language';
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
import { api } from '../../components/config/api';
import Modal from '@mui/material/Modal';

import Header from '../../components/Layout/Header';
import SideBarWeb from '../../components/Layout/SideBarWeb';
import SideBarMobile from '../../components/Layout/SideBarMobile';
import LatestNews from '../../components/Layout/LatestNews';

class P2PListingScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: localStorage.getItem("language"),
			activeTab: "nav-quickbuy",
			modal_myBuyOrder: false,
			modal_mySellOrder: false,
			modal_createSell: false,
			modal_createBuy: false,
		}
	}

	async componentDidMount() {
		window.scrollTo(0, 0)
	}

	render() {

		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1
		};

		return (
			<>
				<Helmet><title>{"FUTURA | P2P Listing"}</title></Helmet>
				<div className="dashboard">
					<Header />

					<div className="container-fluid">
						<div className="row">
							<SideBarWeb />

							<>
								<div className="col content">
									<br />
									<h1>Hello!<br />
										MetaBOBO</h1>
									<div className="panel">
										<div className="row text-center mt-5 mb-5">
											<h4>Coming Soon...</h4>
										</div>
									</div>
									{/* <div className="panel">
										<div className="row gx-3">
											<div className="col-6 col-md-2">
												<div className="fudi">
													<img src="assets/images/fude.png" className="pic" alt="" /> FUDE:0.0000
												</div>
											</div>
											<div className="col-6 col-md-2 order-md-2">
												<div className="dropdown fudi float-end">
													<a className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
														<img src="assets/images/fudi.png" className="pic" alt="" />	FUDI
													</a>
												</div>
											</div>
											<div className="col listing">
												<div className="nav nav-tabs nav-justified" id="nav-tab" role="tablist">
													<button onClick={() => this.setState({ activeTab: "nav-quickbuy" })} className={"nav-link " + ((this.state.activeTab == "nav-quickbuy") ? 'active' : '')} id="nav-quickbuy-tab" data-bs-toggle="tab" data-bs-target="#nav-quickbuy" type="button" role="tab" aria-controls="nav-quickbuy" aria-selected="true">QUICK BUY</button>
													<button onClick={() => this.setState({ activeTab: "nav-quicksell" })} className={"nav-link " + ((this.state.activeTab == "nav-quicksell") ? 'active' : '')} id="nav-quicksell-tab" data-bs-toggle="tab" data-bs-target="#nav-quicksell" type="button" role="tab" aria-controls="nav-quicksell" aria-selected="false">QUICK SELL</button>
													<button onClick={() => this.setState({ activeTab: "nav-buy" })} className={"nav-link " + ((this.state.activeTab == "nav-buy") ? 'active' : '')} id="nav-buy-tab" data-bs-toggle="tab" data-bs-target="#nav-buy" type="button" role="tab" aria-controls="nav-buy" aria-selected="false">BUY</button>
													<button onClick={() => this.setState({ activeTab: "nav-sell" })} className={"nav-link " + ((this.state.activeTab == "nav-sell") ? 'active' : '')} id="nav-sell-tab" data-bs-toggle="tab" data-bs-target="#nav-sell" type="button" role="tab" aria-controls="nav-sell" aria-selected="false">SELL</button>
												</div>
											</div>
										</div>
										<div className="tab-content" id="nav-tabContent">
											<div className={"tab-pane fade " + ((this.state.activeTab == "nav-quickbuy") ? 'show active' : '')} id="nav-quickbuy" role="tabpanel" aria-labelledby="nav-quickbuy-tab" tabIndex="0">
												<div className="swapbox my-4 mx-auto">
													<h3><u>Quick Buy</u></h3>
													<p>Quick Buy operates on a first-come, first-serve basis, utilizing available FUDI in the listing to make purchases swiftly.</p>
													<div className="mt-5 mb-3">
														<input className="form-control" type="text" placeholder="Enter FUEDE Amount" />
													</div>
													<div className="mb-5 position-relative">
														<input className="form-control" type="text" placeholder="Enter OTP" />
														<button type="button" className="btn btn-primary btn-request rounded-pill">Request OTP</button>
													</div>
													<div className="text-center">
														<button type="button" className="btn btn-primary btn-swap rounded-pill px-5">Exchange Now</button>
													</div>
												</div>
												<div className="foot">
													<a href='javascript:void(0)' onClick={() => this.setState({ modal_myBuyOrder: true })} data-bs-toggle="modal" data-bs-target="#myBuyOrder">MY BUY ORDER</a>
												</div>
											</div>
											<div className={"tab-pane fade " + ((this.state.activeTab == "nav-quicksell") ? 'show active' : '')} id="nav-quicksell" role="tabpanel" aria-labelledby="nav-quicksell-tab" tabIndex="0">
												<div className="swapbox my-4 mx-auto">
													<h3><u>Quick Sell</u></h3>
													<p>Quick Sell follows a first-come, first-serve approach, utilizing the total available FUDE in the listing to fulfill orders promptly to interested buyers.</p>
													<div className="mt-5 mb-3">
														<input className="form-control" type="text" placeholder="Enter FUEDE Amount" />
													</div>
													<div className="mb-5 position-relative">
														<input className="form-control" type="text" placeholder="Enter OTP" />
														<button type="button" className="btn btn-primary btn-request rounded-pill">Request OTP</button>
													</div>
													<div className="text-center">
														<button type="button" className="btn btn-primary btn-swap rounded-pill px-5">Exchange Now</button>
													</div>
												</div>
												<div className="foot">
													<a href='javascript:void(0)' onClick={() => this.setState({ modal_mySellOrder: true })} data-bs-toggle="modal" data-bs-target="#mySellOrder">My Sell Order</a>
												</div>
											</div>
											<div className={"tab-pane fade " + ((this.state.activeTab == "nav-buy") ? 'show active' : '')} id="nav-buy" role="tabpanel" aria-labelledby="nav-buy-tab" tabIndex="0">
												<div className="tbl-head">
													<div className="row align-items-center">
														<div className="col-3">
															Seller
														</div>
														<div className="col-7">
															Amount of FUDI Listed for Sale
														</div>
														<div className="col-2">

														</div>
													</div>
												</div>
												<div className="tbl-row">
													<div className="row align-items-center">
														<div className="col-3">
															META ID
														</div>
														<div className="col-7">
															10.0000
														</div>
														<div className="col-2 text-end">
															<a href="javascript:void(0);" className="buy">BUY NOW</a>
														</div>
													</div>
												</div>
												<div className="tbl-row">
													<div className="row align-items-center">
														<div className="col-3">
															META ID
														</div>
														<div className="col-7">
															10.0000
														</div>
														<div className="col-2 text-end">
															<a href="javascript:void(0);" className="buy">BUY NOW</a>
														</div>
													</div>
												</div>
												<div className="tbl-row">
													<div className="row align-items-center">
														<div className="col-3">
															META ID
														</div>
														<div className="col-7">
															10.0000
														</div>
														<div className="col-2 text-end">
															<a href="javascript:void(0);" className="buy">BUY NOW</a>
														</div>
													</div>
												</div>
												<div className="tbl-row">
													<div className="row align-items-center">
														<div className="col-3">
															META ID
														</div>
														<div className="col-7">
															10.0000
														</div>
														<div className="col-2 text-end">
															<a href="javascript:void(0);" className="buy">BUY NOW</a>
														</div>
													</div>
												</div>
												<div className="tbl-row">
													<div className="row align-items-center">
														<div className="col-3">
															META ID
														</div>
														<div className="col-7">
															10.0000
														</div>
														<div className="col-2 text-end">
															<a href="javascript:void(0);" className="buy">BUY NOW</a>
														</div>
													</div>
												</div>
												<div className="tbl-row">
													<div className="row align-items-center">
														<div className="col-3">
															META ID
														</div>
														<div className="col-7">
															10.0000
														</div>
														<div className="col-2 text-end">
															<a href="javascript:void(0);" className="buy">BUY NOW</a>
														</div>
													</div>
												</div>
												<div className="tbl-row">
													<div className="row align-items-center">
														<div className="col-3">
															META ID
														</div>
														<div className="col-7">
															10.0000
														</div>
														<div className="col-2 text-end">
															<a href="javascript:void(0);" className="buy">BUY NOW</a>
														</div>
													</div>
												</div>
												<div className="tbl-row">
													<div className="row align-items-center">
														<div className="col-3">
															META ID
														</div>
														<div className="col-7">
															10.0000
														</div>
														<div className="col-2 text-end">
															<a href="javascript:void(0);" className="buy">BUY NOW</a>
														</div>
													</div>
												</div>
												<div className="tbl-row">
													<div className="row align-items-center">
														<div className="col-3">
															META ID
														</div>
														<div className="col-7">
															10.0000
														</div>
														<div className="col-2 text-end">
															<a href="javascript:void(0);" className="buy">BUY NOW</a>
														</div>
													</div>
												</div>
												<div className="tbl-row">
													<div className="row align-items-center">
														<div className="col-3">
															META ID
														</div>
														<div className="col-7">
															10.0000
														</div>
														<div className="col-2 text-end">
															<a href="javascript:void(0);" className="buy">BUY NOW</a>
														</div>
													</div>
												</div>
												<div className="foot">
													<div className="row align-items-center">
														<div className="col-8 col-md-6">
															<a href='javascript:void(0)' onClick={() => this.setState({ modal_createSell: true })} data-bs-toggle="modal" data-bs-target="#createSell">CREATE AD TO SELL</a> <img src="assets/images/louder.svg" className="mx-2" alt="" />
															<a href='javascript:void(0)' onClick={() => this.setState({ modal_myBuyOrder: true })} data-bs-toggle="modal" data-bs-target="#myBuyOrder">MY BUY ORDER</a>
														</div>
														<div className="col-4 col-md-6 text-end">
															<a href="javascript:void(0);" className="nect">NEXT PAGE</a>
														</div>
													</div>
												</div>
											</div>
											<div className={"tab-pane fade " + ((this.state.activeTab == "nav-sell") ? 'show active' : '')} id="nav-sell" role="tabpanel" aria-labelledby="nav-sell-tab" tabIndex="0">
												<div className="tbl-head">
													<div className="row align-items-center">
														<div className="col-3">
															Seller
														</div>
														<div className="col-7">
															Amount of FUDI Listed for Sale
														</div>
														<div className="col-2">

														</div>
													</div>
												</div>
												<div className="tbl-row">
													<div className="row align-items-center">
														<div className="col-3">
															META ID
														</div>
														<div className="col-7">
															10.0000
														</div>
														<div className="col-2 text-end">
															<a href="javascript:void(0);" className="buy">BUY NOW</a>
														</div>
													</div>
												</div>
												<div className="tbl-row">
													<div className="row align-items-center">
														<div className="col-3">
															META ID
														</div>
														<div className="col-7">
															10.0000
														</div>
														<div className="col-2 text-end">
															<a href="javascript:void(0);" className="buy">BUY NOW</a>
														</div>
													</div>
												</div>
												<div className="tbl-row">
													<div className="row align-items-center">
														<div className="col-3">
															META ID
														</div>
														<div className="col-7">
															10.0000
														</div>
														<div className="col-2 text-end">
															<a href="javascript:void(0);" className="buy">BUY NOW</a>
														</div>
													</div>
												</div>
												<div className="tbl-row">
													<div className="row align-items-center">
														<div className="col-3">
															META ID
														</div>
														<div className="col-7">
															10.0000
														</div>
														<div className="col-2 text-end">
															<a href="javascript:void(0);" className="buy">BUY NOW</a>
														</div>
													</div>
												</div>
												<div className="tbl-row">
													<div className="row align-items-center">
														<div className="col-3">
															META ID
														</div>
														<div className="col-7">
															10.0000
														</div>
														<div className="col-2 text-end">
															<a href="javascript:void(0);" className="buy">BUY NOW</a>
														</div>
													</div>
												</div>
												<div className="tbl-row">
													<div className="row align-items-center">
														<div className="col-3">
															META ID
														</div>
														<div className="col-7">
															10.0000
														</div>
														<div className="col-2 text-end">
															<a href="javascript:void(0);" className="buy">BUY NOW</a>
														</div>
													</div>
												</div>
												<div className="tbl-row">
													<div className="row align-items-center">
														<div className="col-3">
															META ID
														</div>
														<div className="col-7">
															10.0000
														</div>
														<div className="col-2 text-end">
															<a href="javascript:void(0);" className="buy">BUY NOW</a>
														</div>
													</div>
												</div>
												<div className="tbl-row">
													<div className="row align-items-center">
														<div className="col-3">
															META ID
														</div>
														<div className="col-7">
															10.0000
														</div>
														<div className="col-2 text-end">
															<a href="javascript:void(0);" className="buy">BUY NOW</a>
														</div>
													</div>
												</div>
												<div className="tbl-row">
													<div className="row align-items-center">
														<div className="col-3">
															META ID
														</div>
														<div className="col-7">
															10.0000
														</div>
														<div className="col-2 text-end">
															<a href="javascript:void(0);" className="buy">BUY NOW</a>
														</div>
													</div>
												</div>
												<div className="tbl-row">
													<div className="row align-items-center">
														<div className="col-3">
															META ID
														</div>
														<div className="col-7">
															10.0000
														</div>
														<div className="col-2 text-end">
															<a href="javascript:void(0);" className="buy">BUY NOW</a>
														</div>
													</div>
												</div>
												<div className="foot">
													<div className="row align-items-center">
														<div className="col-8 col-md-6">
															<a href='javascript:void(0)' onClick={() => this.setState({ modal_createBuy: true })} data-bs-toggle="modal" data-bs-target="#createBuy">CREATE AD TO BUY</a> <img src="assets/images/louder.svg" className="mx-2" alt="" />
															<a href='javascript:void(0)' onClick={() => this.setState({ modal_mySellOrder: true })} data-bs-toggle="modal" data-bs-target="#mySellOrder">My Sell Order</a>
														</div>
														<div className="col-4 col-md-6 text-end">
															<a href="javascript:void(0);" className="nect">NEXT PAGE</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div> */}
								</div>

								{/* <!-- Modal Buy --> */}
								<Modal open={this.state.modal_myBuyOrder}>
									<div class="modal-dialog modal-dialog-centered">
										<div class="modal-content">
											<div class="modal-header border-0">
												<h1 class="modal-title" id="exampleModalLabel">My Buy Order</h1>
											</div>
											<div class="modal-body">
												<div class="my-5">
													<div class="bar mb-3">
														Purchased Amount: 0.0000
													</div>
													<div class="bar mb-2">
														Amount On Listing: 0.0000
													</div>
												</div>
												<div class="text-center mb-4">
													<button onClick={() => this.setState({ modal_myBuyOrder: false })} type="button" class="btn btn-primary rounded-pill" data-bs-dismiss="modal">Close</button>
												</div>
											</div>
										</div>
									</div>
								</Modal>

								{/* <!-- Modal Sell --> */}
								<Modal open={this.state.modal_mySellOrder}>
									<div class="modal-dialog modal-dialog-centered">
										<div class="modal-content">
											<div class="modal-header border-0">
												<h1 class="modal-title" id="exampleModalLabel">My Sell Order</h1>
											</div>
											<div class="modal-body">
												<div class="my-5">
													<div class="bar mb-3">
														Sold Amount: 0.0000
													</div>
													<div class="bar mb-2">
														Amount On Listing: 0.0000
													</div>
												</div>
												<div class="text-center mb-4">
													<button onClick={() => this.setState({ modal_mySellOrder: false })} type="button" class="btn btn-primary rounded-pill" data-bs-dismiss="modal">Close</button>
												</div>
											</div>
										</div>
									</div>
								</Modal>

								{/* <!-- Modal Create Sell --> */}
								<Modal open={this.state.modal_createSell}>
									<div class="modal-dialog modal-dialog-centered">
										<div class="modal-content">
											<div class="modal-header border-0">
												<h1 class="modal-title" id="exampleModalLabel">Creat AD to Sell</h1>
												<div class="fudi">
													<img src="assets/images/fude.png" class="pic" alt="" /> FUDE:0.0000
												</div>
											</div>
											<div class="modal-body">
												<div class="my-5">
													<p>After each completed transaction, FUDI will instantly reflect in your pocket balance.</p>
													<div class="mb-3">
														<input class="form-control" type="text" placeholder="Enter FUDI Amount" />
													</div>
													<div class="mb-4 position-relative">
														<input class="form-control" type="text" placeholder="Enter OTP" />
														<button type="button" class="btn btn-primary btn-request rounded-pill">Request OTP</button>
													</div>
												</div>
												<div class="text-center pt-4 mb-4">
													<button onClick={() => this.setState({ modal_createSell: false })} type="submit" class="btn btn-primary rounded-pill" data-bs-dismiss="modal">Exchange Now</button>
												</div>
											</div>
										</div>
									</div>
								</Modal>

								{/* <!-- Modal Create Buy --> */}
								<Modal open={this.state.modal_createBuy}>
									<div class="modal-dialog modal-dialog-centered">
										<div class="modal-content">
											<div class="modal-header border-0">
												<h1 class="modal-title" id="exampleModalLabel">Creat AD to Buy</h1>
												<div class="fudi">
													<img src="assets/images/fude.png" class="pic" alt="" /> FUDE:0.0000
												</div>
											</div>
											<div class="modal-body">
												<div class="my-5">
													<p>After each completed transaction, FUDE will instantly reflect in your pocket balance.</p>
													<div class="mb-3">
														<input class="form-control" type="text" placeholder="Enter FUDI Amount" />
													</div>
													<div class="mb-4 position-relative">
														<input class="form-control" type="text" placeholder="Enter OTP" />
														<button type="button" class="btn btn-primary btn-request rounded-pill">Request OTP</button>
													</div>
												</div>
												<div class="text-center pt-4 mb-4">
													<button onClick={() => this.setState({ modal_createBuy: false })} type="submit" class="btn btn-primary rounded-pill" data-bs-dismiss="modal">Exchange Now</button>
												</div>
											</div>
										</div>
									</div>
								</Modal>
							</>

							<LatestNews />

						</div>
					</div>

				</div >
				<SideBarMobile />
			</>
		);
	}
}

export default P2PListingScreen;
