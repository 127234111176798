import React from "react";
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Button, Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { bsc } from 'wagmi/chains'
import { writeContract, erc20ABI, getAccount, watchWalletClient, watchAccount, readContract, signMessage } from '@wagmi/core'

import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { api } from "../../components/config/api";
import { Helmet } from "react-helmet";
import HeaderAuth from "../../components/Layout/HeaderAuth";
const chains = [bsc]
const projectId = '0efa69b6baac1854702c33c9953af8df';
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
	autoConnect: true,
	connectors: w3mConnectors({ projectId, chains }),
	publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)

class LoginScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: localStorage.getItem("language"),
			is_loading: false,
			access_token: "",
			is_just_registered: false,

			contract: null,
			account: null,
			is_connected: false,
			net: "",
			auth_token: "",

			username: "",
			email: "",
			referral_username: "",
			otp: "",
			password: "",
			errorMessage: [],

			account: null,
			report_data: [],
			next_page: null,
			previous_page: null,
			sponsor: "",
		}
	}

	async componentDidMount() {
		const user_token = await localStorage.getItem('user_token');
		if (user_token) {
			setTimeout(() => {
				this.setState({ access_token: user_token });
				window.location.replace('/dashboard');
			});
			return false;
		}

		watchAccount((account) => {
			console.log(account);
			if (account != null || account != undefined) {
				if (account.isConnected) {
					this.setState({ account: account.address });
					this.doSign();
				}
				else {
					this.setState({ is_connected: false, account: account.address })
				}
			}
		});
	}

	async doSign() {
		try {
			const signature = await signMessage({
				message: 'FUTURA_PROJECT',
			})
			this.isConnect(signature)
		}
		catch (e) {

		}
	}

	isConnect(signature) {
		var self = this;
		var json = {
			wallet_address: self.state.account,
			secret_key: signature,
		}
		self.setState({ errorMessage: [] });
		axios({
			method: 'POST',
			url: api.connect,
			data: json,
			headers: {
				'Content-Type': 'application/json;multipart/form-data;boundary=SOME_BOUNDARY',
				'Accept-Language': self.state.lang,
			}
		}).then(async function (ress) {
			console.log(ress.data.data);
			if (ress.data.error == 202 || ress.data.error == '202') {
				if (ress.data.validation && ress.data.validation.length != 0) {
					self.setState({ errorMessage: ress.data.validation });
				} else {
					toast.error(ress.data.message);
				}
			} else {
				toast.success(ress.data.message);
				self.setState({ is_connected: true });
				if (ress.data && ress.data.data) {
					self.setState({ connectData: ress.data.data });
					self.setState({ access_token: (ress.data.data && ress.data.data.token) ? ress.data.data.token : "" });
					if (ress.data.data.need_to_verify) {
						self.setState({ is_connected: true })
						self.setState({ is_just_registered: true })
					} else if(ress.data.data.already_registered) {
						self.setState({ is_just_registered: false })
						await localStorage.setItem('user_token', (ress.data.data && ress.data.data.token) ? ress.data.data.token : "");
						window.location.reload()
					}
				}
			}
		}).catch(function (e) {
			console.log(e);
		});
	};

	getOTP() {
		var self = this;
		var json = {
			email: self.state.email,
		}
		self.setState({ errorMessage: [] });
		axios({
			method: 'POST',
			url: api.verify_send_otp,
			data: json,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json;multipart/form-data;boundary=SOME_BOUNDARY',
				'Accept-Language': self.state.lang,
			}
		}).then(async function (ress) {
			if (ress.data.error == 202 || ress.data.error == '202') {
				if (ress.data.validation && ress.data.validation.length != 0) {
					self.setState({ errorMessage: ress.data.validation });
				} else {
					toast.error(ress.data.message);
				}
			} else {
				toast.success(ress.data.message);
			}
		}).catch(function (e) {
			console.log(e);
		});
	};

	isSubmit() {
		var self = this;
		console.log(self.state.access_token);
		var json = {
			username: self.state.username,
			email: self.state.email,
			referral_username: self.state.referral_username,
			otp: self.state.otp,
		}
		self.setState({ errorMessage: [] });
		axios({
			method: 'POST',
			url: api.verify_save,
			data: json,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json;multipart/form-data;boundary=SOME_BOUNDARY',
				'Accept-Language': self.state.lang,
			}
		}).then(async function (ress) {
			if (ress.data.error == 202 || ress.data.error == '202') {
				if (ress.data.validation && ress.data.validation.length != 0) {
					self.setState({ errorMessage: ress.data.validation });
				} else {
					toast.error(ress.data.message);
				}
			} else {
				toast.success(ress.data.message);
				await localStorage.setItem('user_token', (ress.data.data && ress.data.data.token) ? ress.data.data.token : '');
				toast.success(ress.data.message);
				setTimeout(function () {
					window.location.reload()
				}, 1000)
			}
		}).catch(function (e) {
			console.log(e);
		});
	};

	render() {
		return (
			<>
				<Helmet><title>{"FUTURA | Login"}</title></Helmet>
				<HeaderAuth />
				<WagmiConfig config={wagmiConfig}>
					<div className="wrapper">
						<div className="container-fluid">
							<div className="main">
								<div className="row">
									<div className="col">
										<div className="sign">
											<form>
												<figure>
													<img src="assets/images/logo.png" alt="" />
												</figure>
												<p>Meta-Account</p>
												{
													this.state.is_connected ?
														this.state.onpress ?
															<a href="javascript:void(0);" className="btn btn-primary rounded-pill">
																<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
																<span className="sr-only">Loading...</span>
															</a>
															:
															<>

															</>
														:
														<Web3Button class="btn btn-primary rounded-pill web3" label="Connect To Wallet" icon="hide" />
												}

												{this.state.is_just_registered ?
													<>
														<div className="mb-1">
															<input className="form-control" type="text" name='username' onChange={(e) => this.setState({ username: e.target.value })} value={this.state.username} placeholder="Create Meta ID" />
														</div>
														<div className='mb-3'> {this.state.errorMessage.username && <div className='errorMessage'> {this.state.errorMessage.username[0]} </div>}</div>
														<div className="mb-1">
															<input className="form-control" type="text" name='email' onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} placeholder="Enter Your Email Address" />
														</div>
														<div className='mb-3'> {this.state.errorMessage.email && <div className='errorMessage'> {this.state.errorMessage.email[0]} </div>}</div>
														<div className="mb-1 position-relative">
															<input className="form-control" type="password" name='otp' onChange={(e) => this.setState({ otp: e.target.value })} value={this.state.otp} placeholder="Enter OTP" />
															<button onClick={() => this.getOTP()} type="button" className="btn btn-primary btn-request rounded-pill">Request OTP</button>
														</div>
														<div className='mb-3'> {this.state.errorMessage.otp && <div className='errorMessage'> {this.state.errorMessage.otp[0]} </div>}</div>
														<div className="mb-1">
															<input className="form-control" type="text" name='referral_username' onChange={(e) => this.setState({ referral_username: e.target.value })} value={this.state.referral_username} placeholder="Enter Refcode" />
														</div>
														<div className='mb-3'> {this.state.errorMessage.referral_username && <div className='errorMessage'> {this.state.errorMessage.referral_username[0]} </div>}</div>
														<button onClick={() => this.isSubmit()} type="button" className="btn btn-primary rounded-pill">Create Account</button>
													</>
													: null}

											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</WagmiConfig>
				<Web3Modal
					projectId={projectId}
					ethereumClient={ethereumClient}
					themeMode="dark"
					defaultChain={bsc}
					tokenContracts={{
						1: '0x55d398326f99059fF775485246999027B3197955'
					}}
					themeVariables={{
						'--w3m-accent-fill-color': '#fff',
						'--w3m-background-color': '#5e93de',
						'--w3m-accent-color': '#5e93de'
					}}
				/>
			</>
		);
	}
}

export default LoginScreen;
