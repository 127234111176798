import React from 'react';
import { Link } from 'react-router-dom'
import strings from '../../components/Language';
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
import { api } from '../../components/config/api';

import Header from '../../components/Layout/Header';
import SideBarWeb from '../../components/Layout/SideBarWeb';
import SideBarMobile from '../../components/Layout/SideBarMobile';
import LatestNews from '../../components/Layout/LatestNews';

class SwapScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: localStorage.getItem("language"),
			activeTab: "SWAP"

		}
	}

	async componentDidMount() {
		window.scrollTo(0, 0)
	}

	render() {

		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1
		};

		return (
			<>
				<Helmet><title>{"FUTURA | Swap"}</title></Helmet>
				<div className="dashboard">
					<Header />

					<div className="container-fluid">
						<div className="row">
							<SideBarWeb />
							<>
								<div className="col content">
									<br />
									<h1>Hello!<br /> MetaBOBO</h1>
									<div className="panel">
										<div className="row text-center mt-5 mb-5">
											<h4>Coming Soon...</h4>
										</div>
									</div>
									{/* <div className="panel">
										<div className="py-5">
											<nav className="swap-tab mb-4">
												<div className="nav nav-tabs nav-justified" id="nav-tab" role="tablist">
													<button onClick={() => this.setState({ activeTab: "SWAP" })} className={"nav-link " + ((this.state.activeTab == "SWAP") ? 'active' : '')} id="nav-swap-tab" data-bs-toggle="tab" data-bs-target="#nav-swap" type="button" role="tab" aria-controls="nav-fudi" aria-selected="true">SWAP</button>
													<button onClick={() => this.setState({ activeTab: "CROSS_CHAIN" })} className={"nav-link " + ((this.state.activeTab == "CROSS_CHAIN") ? 'active' : '')} id="nav-cross-tab" data-bs-toggle="tab" data-bs-target="#nav-cross" type="button" role="tab" aria-controls="nav-cross" aria-selected="false">CROSS CHAIN</button>
												</div>
											</nav>
											<div className="swapbox">
												<h3><u>SWAP</u></h3>
												<div className="tab-content" id="nav-tabContent">
													<div className={"tab-pane fade " + ((this.state.activeTab == "SWAP") ? 'show active' : '')} id="nav-swap" role="tabpanel" aria-labelledby="nav-swap-tab" tabIndex="0">
														<div className="bar mb-3">
															<div className="row">
																<div className="col-12 col-md-6">
																	<div className="dropdown">
																		<button className="btn btn-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																			Choose Token
																		</button>
																		<ul className="dropdown-menu">
																			<li><a className="dropdown-item" href="javascript:void(0);">Choose Token</a></li>
																		</ul>
																	</div>
																</div>
															</div>
														</div>
														<div className="mb-3 position-relative">
															<input className="form-control" type="text" placeholder="Enter Amount" />
															<div className="wallet">Wallet Balance:  0.000</div>
														</div>
														<div className="mb-3 text-center">
															<img src="assets/images/rld.png" alt="" />
														</div>
														<div className="mb-3 position-relative">
															<input className="form-control" type="text" placeholder="0.0000" />
															<div className="wallet">Wallet Balance:  0.000</div>
														</div>
														<div className="mb-4 position-relative">
															<input className="form-control" type="text" placeholder="Enter OTP" />
															<button type="button" className="btn btn-primary btn-request rounded-pill">Request OTP</button>
														</div>
														<div className="text-center">
															<button type="button" className="btn btn-primary btn-swap rounded-pill px-5">Swap Now</button>
														</div>
													</div>
													<div className={"tab-pane fade " + ((this.state.activeTab == "CROSS_CHAIN") ? 'show active' : '')} id="nav-cross" role="tabpanel" aria-labelledby="nav-cross-tab" tabIndex="0">
														<div className="bar mb-3 d-none d-md-block">
															<div className="row">
																<div className="col-12 col-md-6">
																	<div className="dropdown">
																		<button className="btn btn-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																			Choose Chain
																		</button>
																		<ul className="dropdown-menu">
																			<li><a className="dropdown-item" href="javascript:void(0);">Choose Chain</a></li>
																		</ul>
																	</div>
																</div>
																<div className="col-12 col-md-6">
																	<div className="dropdown">
																		<button className="btn btn-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																			Choose Token
																		</button>
																		<ul className="dropdown-menu">
																			<li><a className="dropdown-item" href="javascript:void(0);">Choose Token</a></li>
																		</ul>
																	</div>
																</div>
															</div>
														</div>
														<div className="bar mb-2 d-sm-block d-md-none">
															<div className="col-12">
																<div className="dropdown">
																	<button className="btn btn-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																		Choose Chain
																	</button>
																	<ul className="dropdown-menu">
																		<li><a className="dropdown-item" href="javascript:void(0);">Choose Chain</a></li>
																	</ul>
																</div>
															</div>
														</div>
														<div className="bar mb-3 d-sm-block d-md-none">
															<div className="col-12">
																<div className="dropdown">
																	<button className="btn btn-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																		Choose Token
																	</button>
																	<ul className="dropdown-menu">
																		<li><a className="dropdown-item" href="javascript:void(0);">Choose Token</a></li>
																	</ul>
																</div>
															</div>
														</div>
														<div className="mb-3 position-relative">
															<input className="form-control" type="text" placeholder="Enter Amount" />
															<div className="wallet">Wallet Balance:  0.000</div>
														</div>
														<div className="mb-3 text-center">
															<img src="assets/images/rld.png" alt="" />
														</div>
														<div className="bar mb-3">
															<div className="row">
																<div className="col-12 col-md-6">
																	<div className="dropdown">
																		<button className="btn btn-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																			Choose Chain
																		</button>
																		<ul className="dropdown-menu">
																			<li><a className="dropdown-item" href="javascript:void(0);">Choose Chain</a></li>
																		</ul>
																	</div>
																</div>
															</div>
														</div>
														<div className="mb-5 position-relative">
															<input className="form-control" type="text" placeholder="Enter OTP" />
															<button type="button" className="btn btn-primary btn-request rounded-pill">Request OTP</button>
														</div>
														<div className="text-center">
															<button type="button" className="btn btn-primary btn-swap rounded-pill px-5">Swap Now</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div> */}
								</div>
							</>
							<LatestNews />
						</div>
					</div>

				</div >
				<SideBarMobile />
			</>
		);
	}
}

export default SwapScreen;
