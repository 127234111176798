import React from 'react';
import { Link } from 'react-router-dom'
import strings from '../../components/Language';
import { Helmet } from 'react-helmet';

import { api } from '../../components/config/api';
import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import { getToken, getUserData, number_format, storeUserData } from '../../components/Util';

import Header from '../../components/Layout/Header';
import SideBarWeb from '../../components/Layout/SideBarWeb';
import SideBarMobile from '../../components/Layout/SideBarMobile';
import LatestNews from '../../components/Layout/LatestNews';

class FriendListScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: localStorage.getItem("language"),
			is_loading: false,
			access_token: "",
			friends_message: "",
			data: []
		}
	}

	async componentDidMount() {
		let self = this;
		window.scrollTo(0, 0)
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			self.setState({ access_token: token });

			setTimeout(() => {
				self.getData(api.friends, false)
			});
		}
	}

	getData(API, is_back = false) {
		if (API) { } else { return false; }
		var self = this;
		axios({
			method: 'GET',
			url: API,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				console.log(ress.data.data);
				self.setState({
					data: (ress.data.data && ress.data.data.rows && ress.data.data.rows.data) ? ress.data.data.rows.data : [],
					prev_page_url: (ress.data.data && ress.data.data.rows && ress.data.data.rows.prev_page_url) ? ress.data.data.rows.prev_page_url : '',
					next_page_url: (ress.data.data && ress.data.data.rows && ress.data.data.rows.next_page_url) ? ress.data.data.rows.next_page_url : '',
					is_back: is_back,
					friends_message: ress.data.message,
				});
				console.log(self.state.user_data);
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
		}).catch(function (e) {
			//toast.error(e.message);
		});
	};

	render() {
		return (
			<>
				<Helmet><title>{"FUTURA | Friend List"}</title></Helmet>
				<div className="dashboard">
					<Header />

					<div className="container-fluid">
						<div className="row">
							<SideBarWeb />

							<>
								<div className="col content">
									<br />
									<h1>Hello!<br />
										MetaBOBO</h1>
									<div className="panel">
										<div className="row">
											<div className="col-md-6">
												<div className="pocket">
													<div className="px-2">
														<picture>
															<img src="assets/images/avtr.png" alt="" />
														</picture>
													</div>
													<div className="box">
														<div className="row">
															<div className="col-auto mb-2">
																Meta ID/Refcode:
															</div>
															<div className="col-auto mb-2">
																MetaBOBO
															</div>
															<div className="col mb-2 text-end">
																<a href="javascript:void(0);">Copy ID</a>
															</div>
														</div>
														<div className="row">
															<div className="col-auto mb-2">
																Email Address:
															</div>
															<div className="col-auto mb-2">
																Met****@gmail.com
															</div>
															<div className="col mb-2 text-end">
																<a href="javascript:void(0);">Edit</a>
															</div>
														</div>
														<div className="row">
															<div className="col-auto mb-2">
																Wallet Address:
															</div>
															<div className="col-auto mb-2">
																0eX****1kyDx
															</div>
															<div className="col mb-2 text-end">
																<a href="javascript:void(0);">Copy Address</a>
															</div>
														</div>
														<h3>Pocket Balance</h3>
														<div className="row">
															<div className="col-auto">
																<div className="row">
																	<div className="col-auto mb-1">
																		FUD:
																	</div>
																	<div className="col-auto mb-1">
																		20.000
																	</div>
																</div>
																<div className="row">
																	<div className="col-auto mb-1">
																		FUDI:
																	</div>
																	<div className="col-auto mb-1">
																		20.000
																	</div>
																</div>
																<div className="row">
																	<div className="col-auto">
																		FUDE:
																	</div>
																	<div className="col-auto">
																		20.000
																	</div>
																</div>
															</div>
															<div className="col pt-2 text-end">
																<br /><br />
																<a href="javascript:void(0);" className="me-1">Deposit</a> <a href="javascript:void(0);" className="me-1">Withdraw</a> <a href="javascript:void(0);">Transfer</a>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="players">
											<div className='row text-center mb-3'>
												<b>{this.state.friends_message}</b>
											</div>
											<div className="frame">
												{this.state.data && this.state.data.length != 0 ?
													<>
														{this.state.data.map((item, i) =>
															<div>
																<div className="num">{item.direct_children_count}</div>
																<div className="avtr">
																	<img src={item.avatar_url} alt="" />
																	<div className="overlay">{item.username ?? "-"}<br />
																		<a onClick={() => this.getData(api.friends + "?user=" + item.id, true)} href="javascript:void(0);" className="btn btn-primary rounded-pill">View</a>
																	</div>
																</div>
																<p>900.0000</p>
															</div>
														)}
													</>
													:
													<div className='row text-center'>
														<span>No Friends available</span>
													</div>
												}
											</div>

										</div>
										{this.state.is_back ?
											<div className="text-center">
												<a onClick={() => this.getData(api.friends)} href="javascript:void(0);" className="btn btn-primary rounded-pill">My All Members</a>
											</div>
											: null}
									</div>
								</div>
							</>

							<LatestNews />

						</div>
					</div>

				</div>
				<SideBarMobile />
			</>
		);
	}
}

export default FriendListScreen;
