import React from 'react';
import { Link, json } from 'react-router-dom'
import strings from '../../components/Language';
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
import { api } from '../../components/config/api';
import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import { getToken, getUserData, number_format, storeUserData } from '../../components/Util';

import Header from '../../components/Layout/Header';
import SideBarWeb from '../../components/Layout/SideBarWeb';
import SideBarMobile from '../../components/Layout/SideBarMobile';
import LatestNews from '../../components/Layout/LatestNews';

class NodesScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: localStorage.getItem("language"),
			is_loading: false,
			access_token: "",
			data: [],
		}
	}

	async componentDidMount() {
		window.scrollTo(0, 0)
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			var user_data = getUserData();
			this.setState({
				access_token: token,
				user_data: (user_data && user_data.user) ? user_data.user : {},
			});

			setTimeout(() => {
				this.getData(api.node_purchase)
			});
		}
	}

	getData(API) {
		if (API) { } else { return false; }
		var self = this;
		axios({
			method: 'GET',
			url: API,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				self.setState({ data: (ress.data.data && ress.data.data.packages) ? ress.data.data.packages : [] });
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
		}).catch(function (e) {
			//toast.error(e.message);
		});
	};

	packagePurchase(package_id) {
		var self = this;
		if (!package_id) {
			toast.error("Package id not found!");
			return false;
		}
		var json = {
			package_id: package_id
		}
		axios({
			method: 'POST',
			url: api.node_purchase,
			data: json,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				toast.success(ress.data.message);
				self.getData(api.node_purchase);
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
		}).catch(function (e) {
			//toast.error(e.message);
		});
	};

	render() {

		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: (this.state.data && this.state.data.length < 3) ? this.state.data.length : 3,
			slidesToScroll: 1
		};

		return (
			<>
				<Helmet><title>{"FUTURA | Nodes"}</title></Helmet>
				<div className="dashboard">
					<Header />

					<div className="container-fluid">
						<div className="row">
							<SideBarWeb />

							<>
								<div className="col content">
									<br />
									<h1>Hello!<br /> MetaBOBO</h1>
									<div className="panel nodes">
										{/* <div className="owl-carousel owl-theme node"> */}
										{this.state.data && this.state.data.length != 0 ?
											<Slider {...settings}>
												{this.state.data.map((item, i) =>
													<div className="item">
														<div className="found">
															<div className="shade">
																<h1>{item.name}</h1>
																<div className="hilite">
																	<div className="row gx-3">
																		<div className="col-3">
																			{item.usdt}
																		</div>
																		<div className="col-3">
																			USDT
																		</div>
																		<div className="col-6">
																			{item.fud} FUD
																		</div>
																	</div>
																</div>
																<div className="hilite">
																	<div className="row gx-3">
																		<div className="col-3">
																			{item.vault}
																		</div>
																		<div className="col-3">
																			FUD
																		</div>
																		<div className="col-6">
																			IN VAULT
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="purchase" onClick={() => this.packagePurchase(item.id)}>
															<div className="shade">
																<h1>PURCHASE NOW</h1>
																{/* <h1>PURCHASE NOW <i className="fa fa-lock"></i></h1> */}
															</div>
														</div>
													</div>
												)}
											</Slider>
											: null}
										{/* </div> */}
									</div>
								</div>
							</>

							<LatestNews />

						</div>
					</div>

				</div >
				<SideBarMobile />
			</>
		);
	}
}

export default NodesScreen;
